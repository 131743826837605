<template>
  <ScrollableContainer :id="id" :cart-modal="!!isCartModal" :dy-rec-widget="false">
    <ProductCard
      v-for="(rec, i) in recs"
      v-bind="rec"
      :key="id + i"
      :render-mode="!!isCartModal ? 'cartModal' : ''"
      emit-link-click="linkClick"
      @link-click="cartStore.closeCartModal()"
    />
  </ScrollableContainer>
</template>

<script setup lang="ts">
import { generateIdNoDate } from '~/util/generateId';
import { useCartStore } from '~/store/cart';

defineProps({
  recs: {
    type: Array<object>,
    required: true,
  },
});

const id = generateIdNoDate('CtsRecWidget');
const isCartModal = inject<boolean>('isCartModal', false);

const cartStore = useCartStore();
</script>

<style lang="scss" scoped>
:deep(.cart-modal) {
  overflow-x: unset;
  .scroll-container {
    flex-direction: column;
    gap: 18px;
    .product-card-container-cart-modal {
      border: 1px solid $color-neutral-cool-100;
      border-radius: 4px;
      padding: 0.5rem;
      margin: 0;
      background-color: $color-neutral-white;
      .product-card {
        display: flex;
        gap: 20px;
        .product-tag {
          display: none;
        }
        .product-title {
          color: $color-neutral-cool-900;
          font-size: 1rem;
        }
        .product-details {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          .price-container {
            order: 2;
            margin-bottom: 0;
          }
          .yotpo.bottomLine {
            order: 3;
          }
        }
        .image-wrapper {
          overflow: hidden;
          border-radius: 4px;
        }
        .image {
          background-color: $color-neutral-cool-50;
          transition: transform 0.2s;
          height: #{local-functions.rem-calc(80)};
          width: #{local-functions.rem-calc(80)};
        }
        &:hover {
          .image {
            transform: scale(1.1);
          }
        }
        .price-container {
          .current-price,
          .orig-price,
          .orig-price .strikethrough {
            font-size: 0.875rem;
            line-height: 1.5;
          }
          .orig-price {
            margin-left: 5px;
          }
          .savings-cta {
            display: none;
          }
        }
      }
      .add-to-cart-wrapper {
        margin-top: 0;
        .btn {
          margin-left: 100px;
          width: calc(100% - 100px);
        }
      }
    }
  }
}
</style>
